.mobile-apps {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin-left: -12px;
}
.mobile-apps__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  background-color: #333545;
  border-radius: 8px;
  transition: background-color, border-color, ease .3s;
  margin-left: 12px;
  padding: 0 5px;
}
.mobile-apps__link:hover {
  background-color: #3b3d4e;
}
.mobile-apps__image {
  display: block;
}
.mobile-apps__link[hidden] {
  display: none;
}

@media screen and (min-width: 993px) {
  .mobile-apps__link {
    width: 160px;
  }
}