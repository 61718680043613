.mobile-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #17171A;
  z-index: 10;
  overflow: auto;
  transition: left ease .3s;
}
.mobile-menu_active {
  left: 0;
}
.mobile-menu__content {
  padding: 90px 30px 50px;
}
.mobile-menu__list {
  list-style-type: none;
}
.mobile-menu__link {
  display: inline-block;
  vertical-align: top;
  min-width: 110px;
  position: relative;
  font-size: 22px;
  line-height: 28px;
  color: #ffffff;
  text-decoration: none;
  padding: 15px 0;
}
.mobile-menu__link:before {
  content: "";
  width: 0;
  height: 2px;
  background-color: #00C26F;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width ease .3s;
}
.mobile-menu__item:last-child {
  margin-top: 50px;
}
.mobile-menu__item:last-child .mobile-menu__link:before {
  display: none;
}
.mobile-menu__link.active svg {
  fill: #00C26F;
}
.mobile-menu__link.active:before {
  width: 100%;
}

@media screen and (min-width: 993px) {
  .mobile-menu_active {
    left: 0;
    display: none;
  }
}