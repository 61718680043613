.menu {
  display: none;
  flex-grow: 1;
}
.menu__list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
}
.menu__item {
  margin-left: 54px;
}
.menu__item:first-child {
  margin-left: 0;
}
.menu__link {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-decoration: none;
  padding: 11px 0 10px 0;
}
.menu__link:before {
  content: "";
  width: 0;
  height: 2px;
  background-color: #0176FF;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width ease .3s;
}
.menu__link:hover:before {
  width: 100%;
}
.menu__button {
  margin-left: 38px;
}

@media screen and (min-width: 993px) {
  .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}