.brands {
  background-color: #131315;
  padding: 24px 0;
}
.brands__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  flex-wrap: wrap;
}
.brands__item:nth-child(odd) {
  width: 60%;
}
.brands__item:nth-child(even) {
  width: 40%;
}
.brands__item:first-child {
  margin-left: 0;
}

@media screen and (min-width: 560px) {
  .brands__item:nth-child(odd) {
    width: 30%;
  }
  .brands__item:nth-child(even) {
    width: 30%;
  }
}

@media screen and (min-width: 993px) {
  .brands {
    padding: 77px 0;
  }
  .brands__list {
    flex-wrap: nowrap;
  }
  .brands__item:nth-child(odd) {
    width: auto;
  }
  .brands__item:nth-child(even) {
    width: auto;
  }
}