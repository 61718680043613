.product-card {
  position: relative;
  display: block;
  text-decoration: none;
  background-color: #232530;
  border-radius: 16px;
  padding: 24px 16px;
  transition: background-color ease .3s;
}
.product-card:hover {
  background-color: #333545;
}
.product-card__icon img {
  display: block;
  width: 64px;
  height: 64px;
}
.product-card__icon_outside img {
  width: 76px;
  height: 76px;
  margin-bottom: -12px;
}
.product-card__name {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 16px;
}
.product-card__text {
  font-size: 14px;
  line-height: 20px;
  color: #868A99;
  margin-top: 8px;
}
.product-card__applications {
  display: none;
}

@media screen and (min-width: 993px) {
  .product-card {
    min-height: 310px;
    padding: 40px;
  }
  .product-card__name {
    margin-top: 24px;
  }
  .product-card_pointer-events {
    pointer-events: none;
  }
  .product-card_pointer-events:hover {
    background-color: #232530;
  }
  .product-card_pointer-events a {
    pointer-events: auto;
  }
  .product-card__applications {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
  }
  .product-card__applications a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    background-color: #333545;
    border-radius: 8px;
    text-decoration: none;
    margin-left: 14px;
    border: 2px solid transparent;
    transition: border-color ease .3s;
  }
  .product-card__applications a:first-child {
    margin-left: 0;
  }
  .product-card__applications a:hover {
    border-color: #ffffff;
  }
  .product-card__applications img {
    display: block;
  }
}