.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.logo__img {
  display: block;
  width: 32px;
  height: 35px;
}
.logo__text {
  display: none;
  margin-left: 15px;
}

@media screen and (min-width: 480px) {
  .logo__text {
    display: block;
  }
}

@media screen and (min-width: 769px) {
  .logo__img {
    width: 50px;
    height: 56px;
  }
}