.main-screen {
  margin-top: -60px;
  padding-top: 60px;
}
.main-screen__container {
  padding-top: 40px;
  padding-bottom: 40px;
}
.main-screen__picture {
  display: none;
}
.main-screen__picture img {
  display: block;
  margin: 0 auto;
}
.main-screen__title {
  max-width: 440px;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
}
.main-screen__description {
  max-width: 550px;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #A2A7B9;
  opacity: .8;
  margin-top: 12px;
}
.main-screen__form {
  margin-top: 16px;
}
.main-screen__button {
  margin-top: 16px;
}

@media screen and (min-width: 769px) {
  .main-screen__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .main-screen__content {
    flex-grow: 1;
  }
  .main-screen__picture {
    display: block;
    margin-left: 50px;
  }
  .main-screen__button {
    width: auto;
  }
}
@media screen and (min-width: 993px) {
  .main-screen {
    margin-top: -80px;
    padding-top: 80px;
  }
  .main-screen__container {
    padding-top: 120px;
    padding-bottom: 96px;
  }
  .main-screen__title {
    max-width: 640px;
    font-size: 60px;
    line-height: 72px;
  }
  .main-screen__description {
    max-width: 640px;
    color: #ffffff;
    margin-top: 24px;
  }
  .main-screen__form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 24px;
  }
  .main-screen__form-help-text {
    width: 100%;
    order: 3;
    color: #ffffff;
  }
  .main-screen__button {
    margin-left: 16px;
    margin-top: 0;
  }
  .main-screen__picture {
    display: block;
    width: 40%;
  }
}