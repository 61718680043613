.block-to-share {
  background-color: #17171A;
  padding: 32px 0;
}
.block-to-share_section .custom-content-block__icon-before {
  top: 3px;
}

@media screen and (min-width: 769px) {
  .block-to-share {
    padding-top: 98px;
    padding-bottom: 98px;
  }
  .block-to-share_section .custom-content-block {
    padding-top: 76px;
    padding-bottom: 76px;
  }
  .block-to-share_section .custom-content-block__icon-before {
    top: 0;
    left: 31px;
  }
  .block-to-share_section .custom-content-block__icon-after {
    left: 0;
    right: auto;
  }
  .block-to-share_section .custom-content-block__text {
    max-width: 525px;
  }
}