.custom-content-block {
  position: relative;
  background-color: #232530;
  border-radius: 16px;
  padding: 88px 16px 24px;
}
.custom-content-block__icon-before {
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  z-index: 0;
  margin: auto;
}
.custom-content-block__icon-after {
  display: none;
}
.custom-content-block__title {
  position: relative;
  z-index: 1;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
}
.custom-content-block__text {
  position: relative;
  z-index: 1;
  max-width: 500px;
  font-size: 14px;
  line-height: 20px;
  color: #868A99;
  text-align: center;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}
.custom-content-block__button {
  margin-top: 16px;
}

@media screen and (min-width: 769px) {
  .custom-content-block {
    border-radius: 24px;
    padding: 88px 0;
  }
  .custom-content-block__icon-before {
    top: 24px;
    left: 0;
    right: auto;
  }
  .custom-content-block__icon-after {
    display: block;
    position: absolute;
    bottom: 0;
    right: 54px;
  }
  .custom-content-block__title {
    max-width: 580px;
    font-size: 30px;
    line-height: 38px;
    margin-left: auto;
    margin-right: auto;
  }
  .custom-content-block__text {
    max-width: 580px;
    font-size: 16px;
    line-height: 22px;
    margin-top: 24px;
  }
  .custom-content-block__button {
    width: 270px;
    display: block;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 993px) {
  .custom-content-block__title {
    max-width: 664px;
    font-size: 40px;
    line-height: 48px;
  }
}