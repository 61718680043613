.popup {
  position: fixed;
  z-index: 12;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0,0,0, .4);
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  transition: opacity ease .3s;
}
.popup_active {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.popup__content {
  position: relative;
  width: 90%;
  background-color: #17171A;
  border: 1px solid #232530;
  border-radius: 12px;
  padding: 64px 16px;
  margin: auto;
}
.popup__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border: 4px solid transparent;
  cursor: pointer;
}
.popup__close-line {
  position: absolute;
  top: 10px;
  width: 100%;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
}
.popup__close-line:first-child {
  transform: rotate(45deg);
}
.popup__close-line:last-child {
  transform: rotate(-45deg);
}
.popup__icon {
  text-align: center;
}
.popup__icon img {
  display: inline-block;
  vertical-align: top;
}
.popup__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 12px;
}
.popup__description {
  max-width: 435px;
  font-size: 14px;
  line-height: 20px;
  color: #868A99;
  text-align: center;
  margin: 0 auto 24px;
}
.popup__form {
  max-width: 368px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}
.popup__form textarea {
  margin-bottom: 18px;
}
.popup__form-button {
  min-width: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}
.popup_thanks .popup__content {
  background-color: rgba(23, 23, 26, .85);
  padding-top: 168px;
  padding-bottom: 161px;
}
.popup_thanks .popup__description {
  color: #ffffff;
  margin-top: 33px;
}

@media screen and (min-width: 769px) {
  .popup__content {
    width: 100%;
    max-width: 525px;
    padding: 80px;
  }
  .popup__title {
    font-size: 24px;
    line-height: 28px;
  }
  .popup_thanks .popup__content {
    padding-top: 180px;
    padding-bottom: 180px;
  }
  .popup_thanks .popup__description {
    font-size: 16px;
    line-height: 19px;
  }
}