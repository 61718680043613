.advantage {
  background-color: #17171A;
  padding: 32px 0;
}
.advantage__picture {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  margin-bottom: -65px;
}
.advantage__title {
  font-size: 28px;
  line-height: 36px;
  color: #ffffff;
}
.advantage__description {
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #868A99;
  margin-top: 24px;
}
.advantage__link {
  margin-top: 24px;
}
.advantage__link img {
  margin-left: 4px;
  margin-top: -3px;
}
.advantage__payment-systems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 0;
}
.advantage__list {
  font-size: 14px;
  line-height: 32px;
  color: #868A99;
  padding-left: 20px;
  margin-top: 16px;
}
.advantage__mobile-apps {
  margin-top: 16px;
}
.advantage_second,
.advantage_four {
  background-color: #131315;
}
.advantage_second .advantage__title,
.advantage_third .advantage__title {
  max-width: 80%;
}

@media screen and (min-width: 481px) {
  .advantage_third .advantage__title {
    max-width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .advantage {
    padding-top: 98px;
    padding-bottom: 98px;
  }
  .advantage__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .advantage__picture {
    width: 45%;
    margin-bottom: 0;
  }
  .advantage__content {
    width: 49%;
    padding-top: 0;
  }
  .advantage_reverse .advantage__picture {
    width: 40%;
    order: 2;
  }
  .advantage_reverse .advantage__content {
    width: 50%;
    order: 1;
  }
  .advantage__title {
    text-align: left;
  }
  .advantage__description {
    text-align: left;
    margin-top: 24px;
  }
  .advantage__links {
    margin-top: 24px;
  }
  .advantage__payment-systems {
    padding-top: 24px;
  }
  .advantage__list {
    margin-top: 24px;
  }
  .advantage__mobile-apps {
    margin-top: 24px;
  }
  .advantage_second .advantage__title,
  .advantage_third .advantage__title {
    max-width: 100%;
  }
}

@media screen and (min-width: 993px) {
  .advantage__title {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 32px;
  }
  .advantage__description {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 40px;
  }
  .advantage__links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .advantage__link {
    margin-top: 0;
  }
  .advantage__link:first-child {
    margin-left: 0;
  }
  .advantage__link-mobile {
    display: none;
  }
  .advantage__link-desktop {
    display: block;
  }
  .advantage__payment-systems {
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
  }
  .advantage__payment-systems img {
    margin-left: 24px;
  }
  .advantage__payment-systems img:first-child {
    margin-left: 0;
  }
  .advantage_reverse .advantage__picture {
    justify-content: flex-start;
  }
  .advantage_second .advantage__description {
    max-width: 450px;
  }
  .advantage_four .advantage__description {
    max-width: 490px;
  }
}